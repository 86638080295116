<template lang="">
<div>
    <el-button class='mb-4' @click="addType()">Thêm loại sản phẩm</el-button>
    <el-table :data="listType" style="width: 100%" border>
        <el-table-column prop="Name" label="Tên" />
        <el-table-column prop="Link" label="Link" />
        <!-- <el-table-column prop="Img" label="Img" /> -->
        <el-table-column label="Thao tác">
            <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.row)">Sửa</el-button>
                <el-button size="small" type="danger" @click="handleDelete(scope.row)">Xóa</el-button>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
import {
    getListType,
    deleteType
} from '@/api/api';
import {
    ElMessage,
    ElMessageBox
} from 'element-plus'

export default {
    name: "TypePage",
    data() {
        return {
            listType: []
        }
    },
    async created() {
        await this.callListType()
    },
    methods: {
        async callListType() {
            try {
                const response = await getListType()
                if (response.status === 200) {
                    this.listType = response.data
                }
            } catch (err) {
                console.log(err);
            }
        },
        addType() {
            this.$router.push('/adminThuy/type-create')
        },
        handleEdit(e) {
            this.$router.push({
                path: '/adminThuy/type-update',
                query: {
                    id: e.ID
                }
            });
        },
        handleDelete(e) {
            ElMessageBox.confirm(
                    'Bạn có muốn xóa không?',
                    'Cảnh báo', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Hủy',
                        type: 'warning',
                    }
                )
                .then(async () => {
                    try {
                        const response = await deleteType(e.ID)
                        if (response.status === 200) {
                            ElMessage({
                                type: 'success',
                                message: 'Xóa thành công',
                            })
                            this.callListType()
                        }
                    } catch (err) {
                        ElMessage({
                            type: 'info',
                            message: 'Xóa thất bại',
                        })
                    }
                })
                .catch(() => {})
        }
    }
}
</script>
