<template lang="">
    <div>
        day to do list
    </div>
</template>
<script>
export default {
    name: "DayTodolist"
}
</script>
<style lang="">
    
</style>