<template lang="">
    <div>
        Home todolist
    </div>
</template>
<script>
export default {
    name: 'HomeTodoList'
}
</script>
<style lang="">
    
</style>