<template lang="">
<div class="layout__user">
    <div class="menu__over--user">
        <div class="container header__menu">
            <img @click='returnHome()' src='../assets/img/logo_remove_bg.png' alt="Logo" class="w-[130px] h-[68px] object-contain cursor-pointer">
            <div class="flex gap-2 items-center">
                <div class="menu__item--user" @click="$router.push('/')" v-if="!isMobile">
                    Trang chủ
                </div>
                <el-dropdown class="dropdown__custom" trigger="click">
                    <div class="menu__item--user" >
                        Danh mục SP
                        <el-icon class="el-icon--right">
                            <arrow-down />
                        </el-icon>
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="item in dataCategory" :key="item.ID" @click="gotoCategory(item)">
                                {{ item.Name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-badge :value="message.length || 0" class="item">
                    <el-icon @click="drawer = true" class="cursor-pointer mx-2 text-lg">
                        <ShoppingCart />
                    </el-icon>
                </el-badge>
            </div>
        </div>
        <el-drawer v-model="drawer" :before-close="closeDrawer" title="Giỏ hàng" class="!w-full md:!w-[750px] ">
            <div v-if="!message || message.length===0">
                <img src='../assets/img/no_item_cart.webp' alt='No item' class="w-full object-contain" />
                <div class="flex w-full text-center">Chưa có sản phẩm trong giỏ hàng...</div>
            </div>
            <div v-else>
                <p class="text-sm text-red-600 mb-2">* Vuốt sang phải để hiển thị thêm nhiều thông tin của sản phẩm</p>
                <el-table border :data="message" style="width: 100%">
                    <el-table-column fixed label="Thao tác" min-width="100px">
                        <template #default="scope">
                            <el-button type="danger" @click="deleteItem(scope.row.ID)">Xóa</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="NameItem" label="Tên sản phẩm" min-width="150px" />
                    <el-table-column prop="Number" label="Hình ảnh" min-width="100px" />
                    <el-table-column label="Đơn giá" min-width="130px">
                        <template #default="scope">
                            <div>{{formatMoney(scope.row.UnitPrice)}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Number" label="Số lượng" />
                    <el-table-column prop="Number" label="Thành tiền" min-width="130px">
                        <template #default="scope">
                            <div class="whitespace-nowrap"> {{formatMoney(scope.row.Price)}} </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="mt-4 flex gap-4 justify-end items-center">
                    <button class="button__custom" type="success" @click="toPay()">Thanh toán</button>
                    <div> Tổng số tiền: <span class="text-xl text-red-600 font-semibold ">{{ formatMoney(totalPrice()) }}</span></div>
                </div>
            </div>
        </el-drawer>
    </div>
    <div>
        <router-view />
    </div>
    <div class="footer__user--over">
        <el-backtop />
        <div class="container">
            <div class="w-full flex justify-center">
                <img @click='returnHome()' src='../assets/img/logo_remove_bg.png' alt="Logo" class="w-[230px] h-[120px] object-contain cursor-pointer">
            </div>
            <div class='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <p class="footer__des">Đa dạng nguồn hàng giá tốt cho người tiêu dùng</p>
                <div class='mt-4'>
                    <div class="footer__des--over">
                        <el-icon>
                            <Location />
                        </el-icon>
                        <p>Thôn Hậu Trung 1, xã Hồng Bạch, huyện Đông Hưng, tỉnh Thái Bình</p>
                    </div>
                    <!-- <a class="footer__des--over cursor-pointer" href='tel:038818754'>
                        <el-icon>
                            <Phone />
                        </el-icon>
                        <p>038818754</p>
                    </a> -->
                    <div class="footer__des--over">
                        <el-icon>
                            <Message />
                        </el-icon>
                        <p>nguyenthithuy1969@gmail.com</p>
                    </div>
                </div>
            </div>
            <p class='text-center w-full py-8 text-lg '>Copyright © 2024 taphoabathuy.com. All rights reserved.</p>
        </div>
    </div>
</div>
</template>

<script>
import {
    ref,
    onBeforeMount,
    onMounted,
    onBeforeUnmount
} from 'vue';
import {
    useRouter
} from 'vue-router';
import formatMoney from '@/mixin'
import {
    getListType
} from '@/api/api';

export default {
    mixins: [formatMoney],
    setup() {
        const router = useRouter();
        const drawer = ref(false);
        const isMobile = ref(false);

        const message = ref(JSON.parse(localStorage.getItem('shoppingCart')) || '');
        var dataCategory = ref([])

        const menu = ref([{
                name: 'Trang chủ',
                link: '/',
                action() {
                    router.push('/');
                }
            },
            {
                name: 'Sản phẩm',
                link: '/product',
                action() {
                    router.push('/product');
                }
            },
            {
                name: 'Tin tức',
                link: '/news',
                action() {
                    router.push('/news');
                }
            }
        ]);

        const checkScreenSize = () => {
            isMobile.value = window.matchMedia("(max-width: 500px)").matches;
        };

        onBeforeMount(() => {
            getDataType()
        });

        onMounted(() => {
            checkScreenSize();
            window.addEventListener('resize', checkScreenSize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', checkScreenSize);
        });

        const clickMenu = (item) => {
            item.action();
        }
        const deleteItem = (ID) => {
            message.value = message.value.filter(item => item.ID !== ID);
            localStorage.setItem('shoppingCart', JSON.stringify(message.value));
        }
        const returnHome = () => {
            router.push('/');
        }
        const getDataType = async () => {
            const response = await getListType()
            if (response.status === 200) {
                dataCategory.value = response.data
            }
        }
        const totalPrice = () => {
            try {
                let Total = 0
                message.value.map((item) => {
                    Total += item.Price
                })
                return Total
            } catch (error) {
                return 0
            }
        }
        const toPay = () => {
            drawer.value = false
            router.push('/pay');
        }
        const closeDrawer = () => {
            drawer.value = false
            location.reload();
        }
        const gotoCategory = (item) => {
            router.push({
                path: '/category',
                query: {
                    id: item.ID
                }
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        return {
            menu,
            clickMenu,
            returnHome,
            isMobile,
            drawer,
            message,
            totalPrice,
            toPay,
            deleteItem,
            closeDrawer,
            getDataType,
            dataCategory,
            gotoCategory
        }
    }
}
</script>

<style lang="css">
@keyframes colorChange {
    0% {
        text-shadow: 2px 2px 8px #FF0000;
    }

    25% {
        text-shadow: 2px 2px 8px #00FF00;
    }

    50% {
        text-shadow: 2px 2px 8px #0000FF;
    }

    75% {
        text-shadow: 2px 2px 8px #FFFF00;
    }

    100% {
        text-shadow: 2px 2px 8px #FF0000;
    }
}

.logo__menu {
    font-size: 44px;
    font-weight: 700;
    margin-right: 250px;
    animation: colorChange 4s infinite;
    cursor: pointer
}

@media only screen and (max-width: 600px) {
    .logo__menu {
        margin: 0;
        font-size: 32px
    }
}
</style>
