<template lang="">
<div>
    <el-table :data="listTransaction" height="calc(100vh - 80px)" style="width: 100%" border>
        <el-table-column prop="Username" label="Tên" />
        <el-table-column prop="Status" label="Tình trạng" />
        <el-table-column prop="Price" label="Giá">
            <template #default="scope">
                <div>{{ formatMoney(scope.row.Price) }}</div>
            </template>
        </el-table-column>
        <el-table-column prop="ShoppingCart" label="Giỏ hàng" min-width=180>
            <template #default="scope">
                <div v-for="(item, index) in JSON.parse(scope.row.ShoppingCart || '[]')" :key="item.ID" style="margin-bottom: 10px">
                    <div><strong>{{ item.NameItem }}</strong></div>
                    <div>Số lượng: {{ item.Number }}</div>
                    <div>Tiền: {{ formatMoney(item.Price) }}</div>
                    <hr v-if="index < scope.row.ShoppingCart.length - 1" />
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="Phone" label="Số điện thoại" />
        <el-table-column prop="Address" label="Địa chỉ" />
        <el-table-column prop="Note" label="Ghi chú" />
        <el-table-column prop="Email" label="Email" />
        <el-table-column prop="TimeCreate" label="Thời gian tạo đơn" />
        <el-table-column label="Thao tác">
            <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.row)">Sửa</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialogTransaction" title="Sửa giao dịch">
        <el-form :model="form" :rules="rules" ref="formRef" label-width="auto" style="max-width: 600px">
            <el-form-item label="Họ và tên" prop="Username">
                <el-input v-model="form.Username" />
            </el-form-item>
            <el-form-item label="Điện thoại" prop="Phone">
                <el-input v-model="form.Phone" />
            </el-form-item>
            <el-form-item label="Địa chỉ" prop="Address">
                <el-input v-model="form.Address" />
            </el-form-item>
            <el-form-item label="E-mail" prop="Email">
                <el-input v-model="form.Email" />
            </el-form-item>
            <el-form-item label="Ghi chú">
                <el-input v-model="form.Note" />
            </el-form-item>
            <el-form-item>
                <el-button type="success" @click="submitForm">Hoàn tất đặt hàng</el-button>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogTransaction = false">Hủy</el-button>
                <el-button type="primary" @click="dialogTransaction = false">
                    Xác nhận
                </el-button>
            </div>
        </template>
    </el-dialog>
</div>
</template>

<script>
import {
    getTransaction,
    // updateTransaction
} from '@/api/api';
import formatMoney from '@/mixin'

export default {
    name: "TransactionView",
    async created() {
        await this.callListType()
    },
    mixins: [formatMoney],
    data() {
        return {
            listTransaction: [],
            dialogTransaction: false,
            dataTransaction: {},
            form: {
                Username: "",
                Phone: "",
                Address: "",
                Email: "",
                Note: "",
                Price: 0,
                ShoppingCart: []
            },
            rules: {
                Username: [{
                    required: true,
                    message: "Vui lòng nhập họ và tên",
                    trigger: "blur"
                }, ],
                Phone: [{
                        required: true,
                        message: "Vui lòng nhập số điện thoại",
                        trigger: "blur"
                    },
                    {
                        pattern: /^[0-9]{10,11}$/,
                        message: "Số điện thoại không hợp lệ",
                        trigger: "blur",
                    },
                ],
                Address: [{
                    required: true,
                    message: "Vui lòng nhập địa chỉ",
                    trigger: "blur"
                }, ],
                Email: [{
                    type: "email",
                    message: "Email không hợp lệ",
                    trigger: "blur",
                }, ],
            },
        }
    },
    methods: {
        async submitForm() {
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    this.form = {
                        ...this.form,
                        ShoppingCart: this.message,
                        Price: this.message.reduce((total, item) => total + item.Price, 0)
                    };
                } else {
                    console.error("Form validation failed");
                }
            });
        },
        handleEdit(item) {
            console.log('item', item);
            this.dialogTransaction = true
            this.dataTransaction = item
        },
        async callListType() {
            try {
                const response = await getTransaction()
                console.log('response', response);
                if (response.status === 200) {
                    this.listTransaction = response.data
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
}
</script>

<style lang="">

</style>
