<template lang="">
<el-carousel  class="h-[165px] md:h-[500px]">
    <el-carousel-item class="h-[165px] md:h-[500px]">
        <img src='../../../assets/img/slider1.jpg' alt="slider 1" class="carousel-image" />
    </el-carousel-item>
    <el-carousel-item class="h-[165px] md:h-[500px]">
        <img src='../../../assets/img/silder2.jpg' alt="slider 2" class="carousel-image" />
    </el-carousel-item>
</el-carousel>
<div class="container">
    <div class='my-8'>
        <el-row v-for="(item) in listProduct" :key="item.ID" :gutter="10" class='mt-4'>
            <el-col @click="goToCategory(item)" class="header__item uppercase">{{ item.Name }}</el-col>
            <div class="flex flex-wrap justify-between w-full">
                <el-col v-for="(i) in item.Products" :key="i.ID" class="w-full sm:w-[240px] sm:max-w-[240px]">
                    <div class='product__over' @click='clickProduct(i)'>
                        <div class='view__discount' v-if='i.PromotionalPrice !== 0'>
                            -{{ ((1-(i.PromotionalPrice/i.Price))*100).toFixed(0)}}%
                        </div>
                        <div class='product__view--img'>
                            <img v-if="i.Img" :src="`${baseURL}${baseImg}${i.Img}`" :alt="`${i.Name}`" class='product__img object-cover' />
                            <img v-else src='../../../assets/img/logo_remove_bg.png' :alt="`${i.Name}`" class='product__img object-cover' />
                        </div>
                        {{i.Name}}
                        <div class='product__price--view'>
                            <p class='promotionalPrice'>{{formatMoney(i.PromotionalPrice)}}</p>
                            <p :class='i.PromotionalPrice !== 0 ? "money" : "promotionalPrice"'>{{formatMoney(i.Price)}}</p>
                        </div>
                    </div>
                </el-col>
            </div>
        </el-row>
    </div>
</div>
</template>

<script>
import {
    getListProductByType
} from '@/api/api';
import {
    baseURL,
    baseImg
} from '@/assets/variable';
import formatMoney from '@/mixin.js'

export default {
    name: "HomeUser",
    mixins: [formatMoney],
    created() {
        this.callListProduct()
    },
    data() {
        return {
            listProduct: [],
            baseURL,
            baseImg
        }
    },
    methods: {
        goToCategory(item) {
            this.$router.push({
                path: '/category',
                query: {
                    id: item.ID
                }
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        async callListProduct() {
            try {
                const response = await getListProductByType()
                if (response.status === 200) {
                    this.listProduct = response.data
                }
            } catch (err) {
                console.log(err);
            }
        },
        clickProduct(e) {
            this.$router.push({
                path: '/product',
                query: {
                    id: e.ID
                }
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
}
</script>
