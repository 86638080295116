<template lang="">
<div class="container">
    <div class="header__item uppercase mt-8">
        {{ categoryName }}
    </div>
    <div class="flex flex-wrap gap-x-12 gap-y-4 mb-8 mt-4">
        <el-col v-for="(i) in dataShow" :key="i.ID" class="w-full sm:w-[240px] sm:max-w-[240px]">
            <div class='product__over' @click='clickProduct(i)'>
                <div class='view__discount' v-if='i.PromotionalPrice !== 0'>
                    -{{ ((1-(i.PromotionalPrice/i.Price))*100).toFixed(0)}}%
                </div>
                <div class='product__view--img'>
                    <img v-if="i.Img" :src="`${baseURL}${baseImg}${i.Img}`" :alt="`${i.Name}`" class='product__img object-cover' />
                    <img v-else src='../../../assets/img/logo_remove_bg.png' :alt="`${i.Name}`" class='product__img object-cover' />
                </div>
                {{i.Name}}
                <div class='product__price--view'>
                    <p class='promotionalPrice'>{{formatMoney(i.PromotionalPrice)}}</p>
                    <p :class='i.PromotionalPrice !== 0 ? "money" : "promotionalPrice"'>{{formatMoney(i.Price)}}</p>
                </div>
            </div>
        </el-col>
    </div>
    <div class="w-full flex justify-center mb-8">
        <el-pagination v-model:current-page="currentPage" :page-size="12" layout="prev, pager, next, jumper" :total="dataResponse.total" @current-change="handleCurrentChange" />
    </div>
</div>
</template>

<script>
import {
    getListProductByCategory
} from '@/api/api';
import {
    baseURL,
    baseImg
} from '@/assets/variable';
import formatMoney from '@/mixin.js'

export default {
    name: "ListType",
    mixins: [formatMoney],
    data() {
        return {
            id: this.$route.query.id,
            dataListCategory: [],
            dataShow: [],
            categoryName: '',
            baseURL,
            baseImg,
            dataResponse: '',
            currentPage: 1
        }
    },
    watch: {
        '$route.query.id': {
            immediate: true,
            handler(newId, oldId) {
                if (newId !== oldId) {
                    this.id = newId;
                    this.resetView();
                }
            }
        },
    },
    created() {
        this.resetView()
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        resetView() {
            this.setData()
        },
        backToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        async setData() {
            try {
                const response = await getListProductByCategory(this.id)
                this.dataListCategory = response.data.data
                this.categoryName = response.data.categoryName
                this.dataResponse = response.data
                this.dataShow = this.dataListCategory.filter((item, index) => index >= (this.currentPage - 1) * 12 && index < this.currentPage * 12 )
            } catch (error) {
                console.error(error);
            }
        },
        clickProduct(e) {
            this.$router.push({
                path: '/product',
                query: {
                    id: e.ID
                }
            });
            this.backToTop()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.dataShow = this.dataListCategory.filter((item, index) => index >= (this.currentPage - 1) * 12 && index < this.currentPage * 12 )
            this.backToTop()
        }
    },
}
</script>
