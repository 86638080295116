<template lang="">
<div class="flex flex-col items-center">
    <div>
        header
    </div>
    <div class="w-full md:w-[80%]">
        <router-view />
    </div>
</div>
</template>

<script>
export default {
    name: "LayoutTodolist",
}
</script>

<style lang="">

</style>
