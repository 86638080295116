export default {
    methods: {
        formatMoney(item) {
            if(item) {
                let variable = item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                return `${variable}đ`;
            }
            return ''
        },
        getStatus(status) {
            switch (status) {
                case 1:
                    return 'Còn hàng';
                case 0:
                    return 'Hết hàng';
                default:
                    return 'Sai trạng thái';
            }
        },
    }
}