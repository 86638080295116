<template lang="">
    <div class="overLayout">
        <div class="overMenu">
            <div v-for="(item, index) in listMenu" :key="index" @click="changeMenu(item)" :class="[menuChoise === item.Name ? 'menu__item--active' : '', 'menu__item']" >
                {{item.Name}}
            </div>
        </div>
        <div class="over__content">
            <div class="over__header">
                <p class="header__title">Thuy shop</p>
                <div>
                    <p>Thong bao</p>
                </div>
            </div>
            <div class="over__form">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
import { useRoute } from 'vue-router';
export default {
    name: "LayoutAdmin",
    setup() {
        const route = useRoute();
        return {
            route
        };
    },
    created() {
        this.setMenu()
    },
    data() {
        return {
            listMenu: [
                {
                    "Name": "Trang chủ",
                    "Path": "/adminThuy"
                },
                {
                    "Name": "Loại sản phẩm",
                    "Path": "/adminThuy/type"
                },
                {
                    "Name": "Sản phẩm",
                    "Path": "/adminThuy/product"
                },
                {
                    "Name": "Giao dịch",
                    "Path": "/adminThuy/transaction"
                },
            ],
            menuChoise: ""
        }
    },
    methods: {
        setMenu() {
            let menuItem = this.listMenu.find(item => item.Path === this.route.path);
            this.menuChoise = menuItem ? menuItem.Name : '';
        },
        changeMenu(item) {
            this.menuChoise = item.Name
            this.$router.push(item.Path)
        }
    }
}
</script>
<style lang="">

</style>