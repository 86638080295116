<template lang="">
    <div class="container">
        <div class="flex flex-col items-center my-8">
            <img src="@/assets/img/page-not-found.jpg" alt="page_not_found" class="w-full md:w-[500px]" />
            <p @click="gotoHome()" class="button_2">Quay lại trang chủ</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "NotFound",
    methods: {
        gotoHome() {
            this.$router.push({ path: '/' });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    },
}
</script>
<style lang="">
    
</style>