<template lang="">
<div>
    <el-button class="mb-4" @click="addProduct()">Thêm sản phẩm</el-button>
    <el-table :data="listProduct" style="width: 100%" border>
        <el-table-column prop="Name" label="Tên" width="180" />
        <el-table-column label="Giá" >
            <template #default="scope">
                {{formatMoney(scope.row.Price)}}
            </template>
        </el-table-column>
        <el-table-column label="Giá khuyến mãi" >
            <template #default="scope">
                {{formatMoney(scope.row.PromotionalPrice)}}
            </template>
        </el-table-column>
        <el-table-column prop="Quantity" label="Số lượng" />
        <el-table-column label="Mô tả" >
            <template #default="scope">
                <div class="text__clamp">{{scope.row.Description}}</div>
            </template>
        </el-table-column>
        <!-- <el-table-column prop="IDCategory" label="ID Danh mục" /> -->
        <el-table-column label="Trạng thái">
            <template #default="scope">
                {{ getStatas(scope.row.Status) }}
            </template>
        </el-table-column>
        <el-table-column prop="Img" label="Hình ảnh" />
        <el-table-column label="Thao tác">
            <template #default="scope">
                <div class="flex gap-2">
                    <el-button size="small" @click="handleEdit(scope.row)">Sửa</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.row)">Xóa</el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
import {
    getListProduct,
    deleteProduct
} from '@/api/api';
import {
    ElMessage,
    ElMessageBox
} from 'element-plus'
import formatMoney from '@/mixin'

export default {
    name: "TypePage",
    data() {
        return {
            listProduct: []
        }
    },
    created() {
        this.callListProduct()
    },
    mixins: [formatMoney],
    methods: {
        getStatas(status) {
            switch (status) {
                case 1:
                    return 'Còn hàng';
                case 0:
                    return 'Hết hàng';
                default:
                    return 'Sai trạng thái';
            }
        },
        async callListProduct() {
            try {
                const response = await getListProduct()
                if (response.status === 200) {
                    this.listProduct = response.data
                }
            } catch (err) {
                console.log(err);
            }
        },
        addProduct() {
            this.$router.push('/adminThuy/product-create')
        },
        handleEdit(e) {
            this.$router.push({
                path: '/adminThuy/product-update',
                query: {
                    id: e.ID
                }
            });
        },
        handleDelete(e) {
            ElMessageBox.confirm(
                    'Bạn có muốn xóa không?',
                    'Cảnh báo', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Hủy',
                        type: 'warning',
                    }
                )
                .then(async () => {
                    try {
                        const response = await deleteProduct(e.ID)
                        if (response.status === 200) {
                            ElMessage({
                                type: 'success',
                                message: 'Xóa thành công',
                            })
                            this.callListProduct()
                        }
                    } catch (err) {
                        ElMessage({
                            type: 'info',
                            message: 'Xóa thất bại',
                        })
                    }
                })
                .catch(() => {})
        }
    }
}
</script>
