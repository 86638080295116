<template>
<div class="container">
    <div>
        <p class="mt-4 mb-2 text-2xl text-red-500 font-medium">Thông tin giỏ hàng</p>
        <p class="text-sm text-red-600 mb-2">* Vuốt sang phải để hiển thị thêm nhiều thông tin của sản phẩm</p>
        <el-table border :data="message" style="width: 100%">
            <el-table-column fixed label="Thao tác" min-width="100px">
                <template #default="scope">
                    <el-button type="danger" @click="deleteItem(scope.$index)">Xóa</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="NameItem" label="Tên sản phẩm" min-width="150px" />
            <el-table-column prop="Image" label="Hình ảnh" min-width="100px">
                <template #default="scope">
                    <img :src="scope.row.Image" alt="Hình ảnh sản phẩm" class="w-16 h-16 object-cover" />
                </template>
            </el-table-column>
            <el-table-column label="Đơn giá" min-width="130px">
                <template #default="scope">
                    <div>{{ formatMoney(scope.row.UnitPrice) }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="Number" label="Số lượng" />
            <el-table-column label="Thành tiền" min-width="130px">
                <template #default="scope">
                    <div class="whitespace-nowrap"> {{ formatMoney(scope.row.Price) }} </div>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="message.length !== 0">
            <p class="mt-4 mb-2 text-2xl text-red-500 font-medium">Thông tin khách hàng</p>
            <el-form :model="form" :rules="rules" ref="formRef" label-width="auto" style="max-width: 600px">
                <el-form-item label="Họ và tên" prop="Username">
                    <el-input class="h-9" v-model="form.Username" />
                </el-form-item>
                <el-form-item label="Điện thoại" prop="Phone">
                    <el-input class="h-9" v-model="form.Phone" />
                </el-form-item>
                <el-form-item label="Địa chỉ" prop="Address">
                    <el-input class="h-9" v-model="form.Address" />
                </el-form-item>
                <el-form-item label="E-mail" prop="Email">
                    <el-input class="h-9" v-model="form.Email" />
                </el-form-item>
                <el-form-item label="Ghi chú">
                    <el-input class="h-9" v-model="form.Note" />
                </el-form-item>
                <el-form-item>
                    <div class="flex justify-end w-full">
                        <el-button class="button__custom h-[44px] " type="success" @click="submitForm">Hoàn tất đặt hàng</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div v-else class="mt-4 mb-8 text-center text-xl">
            Giỏ hàng hiện tại đang trống, mời quay lại sau.
        </div>
    </div>
</div>
</template>

<script>
import formatMoney from "@/mixin";
import {
    addTransaction
} from "@/api/api";
import {
    ElMessage
} from "element-plus";

export default {
    name: "PayPage",
    mixins: [formatMoney],
    data() {
        return {
            message: JSON.parse(localStorage.getItem("shoppingCart") || "[]"),
            form: {
                Username: "",
                Phone: "",
                Address: "",
                Email: "",
                Note: "",
                Price: 0,
                ShoppingCart: []
            },
            rules: {
                Username: [{
                    required: true,
                    message: "Vui lòng nhập họ và tên",
                    trigger: "blur"
                }, ],
                Phone: [{
                        required: true,
                        message: "Vui lòng nhập số điện thoại",
                        trigger: "blur"
                    },
                    {
                        pattern: /^[0-9]{10,11}$/,
                        message: "Số điện thoại không hợp lệ",
                        trigger: "blur",
                    },
                ],
                Address: [{
                    required: true,
                    message: "Vui lòng nhập địa chỉ",
                    trigger: "blur"
                }, ],
                Email: [{
                    type: "email",
                    message: "Email không hợp lệ",
                    trigger: "blur",
                }, ],
            },
        };
    },
    created() {
        this.createInfoCustomer()
    },
    methods: {
        createInfoCustomer() {
            let infoCustomer = JSON.parse(localStorage.getItem('infoCustomer'))
            if (infoCustomer) {
                this.form = {
                    ...this.form,
                    Username: infoCustomer.Username,
                    Phone: infoCustomer.Phone,
                    Address: infoCustomer.Address,
                }
            }
        },
        async submitForm() {
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    this.form = {
                        ...this.form,
                        ShoppingCart: this.message,
                        Price: this.message.reduce((total, item) => total + item.Price, 0)
                    };
                    const response = await addTransaction(this.form)
                    if (response.data.success === true) {
                        ElMessage({
                            message: 'Đã tạo đơn hàng thành công, bạn chú ý điện thoại để nhân viên sẽ liên hệ.',
                            type: 'success',
                        })
                        localStorage.setItem("infoCustomer", JSON.stringify(this.form));
                        localStorage.removeItem('shoppingCart');
                        location.reload();
                        this.$router.push({path: '/'});
                    } else {
                        ElMessage({
                            message: 'Tạo đơn thất bại, hãy kiểm tra lại hoặc liên hệ với admin',
                            type: 'error',
                        })
                    }
                } else {
                    console.error("Form validation failed");
                }
            });
        },
        deleteItem(index) {
            this.message.splice(index, 1);
            localStorage.setItem("shoppingCart", JSON.stringify(this.message));
            location.reload();
        },
    },
};
</script>
