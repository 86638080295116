import { createWebHistory, createRouter } from 'vue-router'

import NotFound from '../pages/notFound.vue'
import AdminHome from '../pages/admin/AdminHome.vue'

import indexType from '../pages/admin/type/index.vue'
import TypeCreate from '../pages/admin/type/TypeCreate.vue'
import TypeUpdate from '../pages/admin/type/TypeUpdate.vue'

import indexProduct from '../pages/admin/product/index.vue'
import ProductCreate from '../pages/admin/product/ProductCreate.vue'
import ProductUpdate from '../pages/admin/product/ProductUpdate.vue'
import TransactionView from '../pages/admin/transaction'

import LoginView from '../pages/admin/login/LoginView.vue'
import LayoutAdmin from '@/components/LayoutAdmin.vue'
import LayoutUser from '@/components/LayoutUser.vue'

import HomeUser from '../pages/user/trang-chu/HomeUser.vue'
import DetailProduct from '../pages/user/chi-tiet/DetailProduct.vue'
import PayPage from '../pages/user/thanh-toan/PayPage.vue'
import ListType from '@/pages/user/loai-sp/ListType.vue'

import HomeTodoList from '@/pages/todolist/Home.vue'
import DayTodolist from '@/pages/todolist/day/DayTodolist.vue'
import LayoutTodolist from '@/components/LayoutTodolist.vue'

const routes = [
	{
		path: '/',
		component: LayoutUser,
		children: [
			{
				path: '',
				component: HomeUser,
			},
			{
				path: '/product',
				component: DetailProduct,
			},
			{
				path: '/category',
				component: ListType,
			},
			{
				path: '/pay',
				component: PayPage
			}
		]
	},
	{ path: '/login', component: LoginView },
	{
		path: '/adminThuy',
		component: LayoutAdmin,
		children: [
			{
				path: '',
				component: AdminHome,
			},
			{
				path: 'type',
				component: indexType,
				meta: { requiresAuth: true }
			},
			{
				path: 'type-create',
				component: TypeCreate,
				meta: { requiresAuth: true }
			},
			{
				path: 'type-update',
				component: TypeUpdate,
				meta: { requiresAuth: true }
			},
			{
				path: 'product',
				component: indexProduct,
				meta: { requiresAuth: true }
			},
			{
				path: 'product-create',
				component: ProductCreate,
				meta: { requiresAuth: true }
			},
			{
				path: 'product-update',
				component: ProductUpdate,
				meta: { requiresAuth: true }
			},
			{
				path: 'transaction',
				component: TransactionView,
				meta: { requiresAuth: true }
			},
		],
	},
	{
		path: '/todolist',
		component: LayoutTodolist,
		children: [
			{
				path: '',
				component: HomeTodoList,
			},
			{
				path: 'day',
				component: DayTodolist,
			},
		]
	},
	{ 
		path: '/:catchAll(.*)', 
		component: LayoutUser,
		children: [
			{
				path: '',
				component: NotFound,
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

router.beforeEach((to, from, next) => {
	const isAuthenticated = checkAuthentication(); // Kiểm tra xem người dùng đã đăng nhập hay chưa

	if (to.path.startsWith('/adminThuy')) {
		// Nếu là route trong adminThuy, kiểm tra điều kiện đăng nhập
		if (!isAuthenticated) {
			next('/'); // Chuyển hướng đến trang đăng nhập nếu chưa đăng nhập
		} else {
			next(); // Tiếp tục điều hướng nếu đã đăng nhập
		}
	} else {
		next(); // Nếu không thuộc adminThuy, cho phép điều hướng bình thường
	}
})

function checkAuthentication() {
	// Kiểm tra xem người dùng đã đăng nhập hay không
	// Return true nếu đã đăng nhập, false nếu chưa đăng nhập
	// return falsez
	return true
}

export default router