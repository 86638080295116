<template lang="">
<div>
    <p>Thêm sản phẩm</p>
    <el-form style="max-width: 600px" :model="dataProduct" label-width="auto" class="demo-dynamic">
        <el-form-item prop="Name" label="Tên sản phẩm" :rules="[{
              required: true,
              message: 'Không được để trống tên loại sản phẩm',
              trigger: 'blur',
            }]">
            <el-input v-model="dataProduct.Name" />
        </el-form-item>
        <el-form-item prop="Img" label="Img">
            <div class='flex items-center'>
                <input type="file" @input="changeFile" required="required" multiple="multiple" />
                <img :src="`${baseURL}${baseImg}${dataProduct.Img}`" :alt="`${dataProduct.Name}`" class='form__img' />
            </div>
        </el-form-item>
        <el-form-item prop="IDCategory" label="Loại sản phẩm" :rules="[{
              required: true,
              message: 'Không được để trống category',
              trigger: 'blur',
            }]">
            <el-select v-model="dataProduct.IDCategory" placeholder="Select" style="width: 100%">
                <el-option v-for="item in listType" :key="item.ID" :label="item.Name" :value="item.ID" />
            </el-select>
        </el-form-item>
        <el-form-item prop="Price" label="Tiền gốc" :rules="[{
              required: true,
              message: 'Không được để trống tiền',
              trigger: 'blur',
            }]">
            <el-input v-model="dataProduct.Price" :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="(value) => value.replace(/\$\s?|(,*)/g, '')" />
        </el-form-item>
        <el-form-item prop="PromotionalPrice" label="Tiền khuyến mãi">
            <el-input v-model="dataProduct.PromotionalPrice" :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="(value) => value.replace(/\$\s?|(,*)/g, '')" />
        </el-form-item>
        <el-form-item prop="Description" label="Mô tả">
            <el-input v-model="dataProduct.Description" />
        </el-form-item>
        <el-form-item prop="Status" label="Tình trạng">
            <el-select v-model="dataProduct.Status" placeholder="Select" style="width: 100%">
                <template #label="{ label }"> <span>{{ label }}: </span> </template>
                <el-option v-for="item in listStatus" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
        </el-form-item>
        <el-form-item prop="Quantity" label="Số lượng" :rules="[{
              required: true,
              message: 'Không được để trống số lượng',
              trigger: 'blur',
            }]">
            <el-input v-model="dataProduct.Quantity" />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="status==='update' ? updateProduct() : createProduct() ">
                {{status==='update'? 'Cập nhật' : 'Thêm sản phẩm' }}
            </el-button>
            <el-button @click="back()">Quay lại</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
import {
    addProduct,
    getOneProduct,
    updateProduct,
    getListType
} from '@/api/api'
import {
    ElMessage
} from 'element-plus';
import {
    baseURL,
    baseImg
} from '@/assets/variable';

export default {
    name: "ProductForm",
    props: ['status'],
    async created() {
        await this.callListType()
        if (this.status === 'update') {
            this.setData()
        }
    },
    data() {
        return {
            listStatus: [{
                    label: 'Không còn hàng',
                    value: 0
                },
                {
                    label: 'Còn hàng',
                    value: 1
                }
            ],
            dataProduct: {
                Name: "",
                Img: "",
                Category: "",
                IDCategory: "",
                Price: "",
                PromotionalPrice: "",
                Description: "",
                Quantity: "",
                Status: 1
            },
            id: this.$route.query.id,
            listType: [],
            baseURL,
            baseImg
        }
    },
    methods: {
        changeSelect() {
            this.dataProduct.IDCategory = this.listType.find(item => item.Name === this.dataProduct.Category).ID
        },
        async callListType() {
            try {
                const response = await getListType()
                if (response.status === 200) {
                    this.listType = response.data
                }
            } catch (err) {
                console.log(err);
            }
        },
        async changeFile(e) {
            const file = e.target.files[0]; // Lấy file từ sự kiện onChange
            const formData = new FormData(); // Tạo FormData object để chứa file
            formData.append('image', file); // Thêm file vào FormData object với key 'image'
            try {
                const response = await fetch(`${baseURL}/upload`, {
                    method: 'POST',
                    body: formData, // Đặt FormData object làm body của request
                });

                if (response.ok) {
                    const data = await response.json();
                    this.dataProduct.Img = data.filePath
                } else {
                    console.error('Đã xảy ra lỗi khi tải lên file.');
                }
            } catch (error) {
                console.error('Đã xảy ra lỗi khi gửi request:', error);
            }
        },
        back() {
            this.$router.go(-1)
        },
        async setData() {
            const response = await getOneProduct(this.id)
            this.dataProduct = response.data
        },
        async updateProduct() {
            try {
                const response = await updateProduct(this.id, this.dataProduct)
                if (response.data.success === true) {
                    ElMessage({
                        type: 'success',
                        message: 'Cập nhật thành công',
                    })
                    this.back()
                }
            } catch (error) {
                console.log(error);
            }
        },
        async createProduct() {
            try {
                const response = await addProduct(this.dataProduct)
                if (response.status === 201) {
                    ElMessage({
                        type: 'success',
                        message: 'Thêm thành công',
                    })
                    this.back()
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
